<template>
	<el-dialog
		:title="title"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="登记时间">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.registerTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="描述">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="5" v-model="dataForm.situationContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "qualitySituationList-add-or-update",
	  data() {
	    return {
	    	title: '',
			addImg: addImg,
			id: "",
			visible: true,
			action:"",
			form:{},
			subjectList:[],
			majorList:[],
			categoryList:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recNo:"",
				situationContent:"",
				registerTime:"",
				registerMan:"",
				situationType:''
			},
			dataRule:{

			}
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id,situationType) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recNo:"",
				situationContent:"",
				registerTime:"",
				situationType: '',
				registerMan:$common.getItem("userName"),
			};
			this.dataForm.recNo = id?id:0;
			this.dataForm.situationType = situationType;
			this.dataForm.registerMan = $common.getItem("userName");
			this.title = '新增'+this.getSituationTypeName();
			if(id){
				this.getDetail();
				this.title = '修改'+this.getSituationTypeName();
			}
		    this.$nextTick(() => {
		        this.visible = true;
		    })
		},
		  getSituationTypeName(){
			  if (this.dataForm.situationType === '00'){
				  return '停止点检查（报验)情况 总体描述';
			  }else  if (this.dataForm.situationType === '10'){
				return '质量问题跟踪';
			}  else if (this.dataForm.situationType === '15'){
				  return '巡检检查情况-总体描述';
			  } else if (this.dataForm.situationType === '20'){
				return '突出问题跟踪';
			} else if (this.dataForm.situationType === '30'){
				return 'HSE情况';
			} else if (this.dataForm.situationType === '40'){
				return '设计修改及审图意见落实情况';
			} else if (this.dataForm.situationType === '99'){
				return '其他需要说明的问题';
			} else if (this.dataForm.situationType === '80'){
				  return '本周监理工作概括-情况综述描述';
			  } else if (this.dataForm.situationType === '81'){
				  return '进度情况-进度分析描述';
			  } else if (this.dataForm.situationType === '82'){
				  return '进度情况-设计进度情况描述';
			  } else if (this.dataForm.situationType === '83'){
				  return '进度情况-生产及技术准备情况描述';
			  } else if (this.dataForm.situationType === '84'){
				  return '一周质量情况-巡检情况描述';
			  }else if (this.dataForm.situationType === '85'){
				  return '安全生产/HSE情况描述';
			  }else if (this.dataForm.situationType === '86'){
				  return '监理建议或意见描述';
			  }

		  },
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/qualitysituationinfo/info/"+this.dataForm.recNo,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				this.dataForm = data.body;
				if (!this.dataForm.registerMan){
					this.dataForm.registerMan = $common.getItem("userName");
				}
			  }
			});
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
			  let _url = "/business/qualitysituationinfo/save";
			  if(this.dataForm.recNo && this.dataForm.recNo != 0){
				_url = "/business/qualitysituationinfo/update";
			  }
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>
